<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>{{ isFutureLead ? "Prospective future candidates" : "Candidates" }}</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ {{ isFutureLead ? "Prospective future candidates" : "Candidates" }}
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn
                      @click="createUser()"
                      class="mt-1 btn btn-primary"
                  >
                    <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                    Add New
                  </v-btn>
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <div class="row">

                <v-col cols="12" sm="6" md="3">
                  <v-text-field
                      v-on:keyup.enter="searchUser"
                      @input="search.info = $event !== null ? $event : ''"
                      label="Lookup by Name, Unique Identifier, Email or Phone"
                      v-model="search.info" outlined dense clearable
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="3">
                  <v-text-field
                      v-on:keyup.enter="searchUser"
                      @input="search.mobile = $event !== null ? $event : ''"
                      label="Phone / Mobile No."
                      v-model="search.mobile" outlined dense clearable
                  >
                  </v-text-field>
                </v-col>
<!--                <v-col class="d-flex" cols="12" sm="6" md="2">-->
<!--                  <v-select-->
<!--                      :items="completeSteps"-->
<!--                      v-model="search.complete_step"-->
<!--                      label="Step"-->
<!--                      item-text="name"-->
<!--                      item-value="value"-->
<!--                      outlined-->
<!--                      v-on:keyup.enter="searchUser"-->
<!--                      dense clearable-->
<!--                  ></v-select>-->
<!--                </v-col>-->

                <v-col class="d-flex" cols="12" sm="6" md="3">
                  <v-select
                      :items="status"
                      v-model="search.is_active"
                      label="Status"
                      item-text="name"
                      item-value="value"
                      outlined
                      v-on:keyup.enter="searchUser"
                      dense clearable
                  ></v-select>
                </v-col>

                <v-col cols="12" sm="6" md="3">
                  <v-btn
                      :loading="isLoading"
                       @click="searchUser"
                       class="mt-1 btn  btn-primary"
                  >
                    <v-icon small  elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </div>
            </div>

            <div class="table-responsive">
              <v-skeleton-loader
                  v-if="loading"
                  type="table-thead"
              >
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="loading"
                  type="table-row-divider@25"
              >
              </v-skeleton-loader>
              <table class="table" v-if="!loading">
                <thead>
                  <tr class="text-left">
                    <th class="px-3" ><strong>Unique Identifier</strong></th>
                    <th><strong>Last Name</strong></th>
                    <th class="px-3" :class="sortedClass('first_name')" @click="sortBy('first_name')"><strong>First Name</strong></th>
                    <th><strong>Email</strong></th>
                    <th><strong>Phone / Mobile</strong></th>
                    <th v-if="currentUser && currentUser.access_type == 'head'">Branch</th>
                    <th><strong>Business name</strong></th>
                    <th><strong>Status</strong></th>
                    <th class="pr-3 text-center"><strong>Action</strong></th>
                  </tr>
                </thead>
                <tbody>
                <template>
                  <tr v-for="(item, index) in sortedItems" :key="index" >
                    <td>
                      <a @click="studentProfile(item.id)" class="text-primary font-weight-bolder text-hover-primary mb-1 font-size-lg">
                        <v-avatar
                          class="profile"
                          color="grey"
                          size="30"
                        >
                          <v-img v-if="item.image_path" :src="item.image_path['thumb']"></v-img>
                          <img
                            v-else
                            :alt="item.full_name"
                            :src="`https://ui-avatars.com/api/?background=0D8ABC&color=fff&name=${item.full_name}`"
                          >
                        </v-avatar>
                       &nbsp;&nbsp; <a @click="studentProfile(item.id)" class="text-primary font-weight-bolder text-hover-primary mb-1 font-size-lg">
                        {{ item.unique_identifier }}
                        </a>
                      </a>

                    </td>

                    <td class="text-left">
                      {{ item.last_name }}
                    </td>

                    <td>
                      <a @click="studentProfile(item.id)" class="text-primary font-weight-bolder text-hover-primary mb-1 font-size-lg">{{ item.first_name }}</a>
                    </td>

                    <td>
                      {{ item.email }}
                    </td>

                    <td>
                      {{ item.phone || item.mobile }}
                    </td>

                    <td class="px-2" v-if="currentUser && currentUser.access_type == 'head'">{{item.branch_name || 'N/A'}}</td>

                    <td>
                      {{ item.agent_business_name }}
                    </td>

                    <td>
                        <span class="badge badge-success text-lg`"
                              v-bind:class="{ 'badge-success': item.is_active, 'badge-danger': !item.is_active }"
                        >{{ item.is_active ? 'Active' : 'Inactive' }}</span>
                    </td>
                    <td class="pr-0 text-center">
                      <template>
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">

                            <b-dropdown-text tag="div" class="navi-item"  v-if="checkIsAccessible('user', 'edit')">
                              <a @click="editUser(item)" class="navi-link">
                                  <span class="navi-icon">
                                      <v-icon color="blue darken-2">fas fa-pen</v-icon>
                                  </span>
                                <span class="navi-text">Edit</span>
                              </a>
                            </b-dropdown-text>

                            <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('user', 'edit')">
                              <a @click="changePassword(item)" class="navi-link">
                                  <span class="navi-icon">
                                      <v-icon color="blue darken-2">fas fa-key</v-icon>
                                  </span>
                                <span class="navi-text">Change password</span>
                              </a>
                            </b-dropdown-text>

                            <b-dropdown-text tag="div" class="navi-item" >
                              <a @click="updateFutureLead(item)" class="navi-link">
                                  <span class="navi-icon">
                                      <v-icon color="blue darken-2">fas fa-school</v-icon>
                                  </span>
                                <span class="navi-text">{{ item.is_future_lead ? "Unmark as future lead" : "Mark as future lead" }}</span>
                              </a>
                            </b-dropdown-text>

                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click="makeAgent(item.id)" class="navi-link">
                                  <span class="navi-icon">
                                      <v-icon color="blue darken-2">fas fa-user</v-icon>
                                  </span>
                                <span class="navi-text">Make Agent</span>
                              </a>
                            </b-dropdown-text>

                            <b-dropdown-text tag="div" class="navi-item"  v-if="checkIsAccessible('user', 'edit')">
                              <a @click="studentProfile(item.id)" class="navi-link">
                                  <span class="navi-icon">
                                      <v-icon color="blue darken-2">fas fa-eye</v-icon>
                                  </span>
                                <span class="navi-text">View Profile</span>
                              </a>
                            </b-dropdown-text>

                            <b-dropdown-text tag="div" class="navi-item">
                              <a class="navi-link" @click.prevent="deleteUser(item.id)">
                                  <span class="navi-icon">
                                      <v-icon color="red darken-2">fas fa-trash</v-icon>
                                  </span>
                                <span class="navi-text">Delete</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                  <tr v-if="sortedItems.length == 0" >
                    <td colspan="9" class="text-center"><strong>No Data Found</strong></td>
                  </tr>
                </template>
                </tbody>
              </table>
              <b-pagination
                  v-if="sortedItems.length > 0"
                  class="pull-right mt-7"
                  @input="getAllUsers"
                  :disabled="loading"
                  v-model="page"
                  :total-rows="total"
                  :per-page="perPage"
                  first-number
                  last-number
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
      <create-and-update ref="create-and-update" @refresh="getAllUsers"></create-and-update>
      <user-password-change ref="user-password-change" @refresh="getAllUsers"></user-password-change>

      <v-dialog
          v-model="dialog"
          hide-overlay
          persistent
          width="300"
      >
        <v-card
            color="primary"
            dark
        >
          <v-card-text>
            Processing Your Request
            <v-progress-linear
                indeterminate
                color="white"
                class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog
          v-model="dialogFutureLead"
          hide-overlay
          persistent
          width="800"
      >
        <v-card>
          <v-toolbar dark>
            <v-card-title class="text-h5">
              <span>Additional Info - {{studentDetail.full_name}}</span>
              <hr>
            </v-card-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-spacer></v-spacer>
              <v-btn icon dark @click="dialogFutureLead = false">
                <v-icon color="black">mdi-close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" md="6">
                  <strong>Visa expired date:</strong> {{ studentDetail.formatted_visa_expired_date || "N/A" }}
                </v-col>

                <v-col cols="12" md="6">
                  <strong>End date program:</strong> {{ studentDetail.formatted_end_date_program || "N/A"}}
                </v-col>

                <v-col cols="12" md="6">
                  <strong>
                    Applied College :
                  </strong>
                  {{ studentDetail.applied_college || "N/A"}}
                </v-col>

                <v-col cols="12" md="6">
                  <strong>
                    Applied Program :
                  </strong>
                  {{ studentDetail.applied_program || "N/A"}}
                </v-col>

                <v-col cols="12" md="6">
                  <strong>
                    Level of Program :
                  </strong>
                  {{ studentDetail.education_level_title || "N/A"}}
                </v-col>

                <v-col cols="12" md="6">
                  <strong>
                    Applied session :
                  </strong>
                  {{ studentDetail.applied_session_title || "N/A"}}
                </v-col>

                <v-col cols="12" md="6">
                  <strong>
                    Submitted to Institution :
                  </strong>
                  <span class="badge" :class="studentDetail.is_submitted_school ? 'badge-success' : 'badge-danger' ">
                      {{ studentDetail.is_submitted_school ? 'Yes' : 'No' }}
                  </span>
                </v-col>

                <v-col cols="12" md="6">
                  <strong>
                    Offer Issued :
                  </strong>
                  <span class="badge" :class="studentDetail.is_ready_enroll ? 'badge-success' : 'badge-danger' ">
                      {{ studentDetail.is_ready_enroll ? 'Yes' : 'No' }}
                  </span>
                </v-col>

                <v-col cols="12" md="6">
                  <strong>
                    Ready for Visa :
                  </strong>
                  <span class="badge" :class="studentDetail.is_ready_for_visa ? 'badge-success' : 'badge-danger' ">
                      {{ studentDetail.is_ready_for_visa ? 'Yes' : 'No' }}
                  </span>
                </v-col>

                <v-col cols="12" md="6">
                  <strong>
                    Visa Decision :
                  </strong>
                  <span class="badge badge-success" v-if="studentDetail.application_status == 'granted'">
                      Granted
                  </span>

                  <span class="badge badge-primary" v-if="studentDetail.application_status == 'waiting'">
                      Waiting
                  </span>

                  <span class="badge badge-danger" v-if="studentDetail.application_status == 'rejected'">
                      Rejected
                  </span>
                </v-col>

                <v-col cols="12" md="12">
                  <strong>
                    Visa Remarks:
                  </strong>
                  <span class="my-1 text-h6" v-html="studentDetail.application_remarks"></span>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </v-app>
</template>
<script>
import CreateAndUpdate from "@/view/pages/view/user/CreateAndUpdate";
import UserService from "@/services/user/UserService";
import UserPasswordChange from "@/view/pages/view/user/general/PasswordUpdate";

const user = new UserService();

export default {
  name: "Index",
  components: {
    CreateAndUpdate,
    UserPasswordChange
  },
  data() {
    return{
      loading: false,
      dialog: false,
      dialogFutureLead: false,
      isLoading: false,
      users: [],
      total: null,
      perPage: null,
      page: null,
      sort: {
        key: '',
        isAsc: false
      },
      studentDetail: {},
      completeSteps: [
        { name: 'All', value: ''},
        { name: 'InComplete Step', value: '0'},
        { name: 'Complete Step', value: '6'},
      ],
      status: [
        { name: 'Active', value: '1'},
        { name: 'Inactive', value: '0'},
      ],
      search:{
        info:'',
        is_active:'',
        type:'',
        mobile: '',
        is_future: '',
        complete_step: '',
        is_student: '1'
      },
    }
  },
  mounted() {
    if(this.isFutureLead) {
      this.search.is_future_lead = 1;
    }
    this.getAllUsers();
  },
  computed: {
    sortedItems() {
      const list = this.users.slice();
      if (!!this.sort.key) {
        list.sort((a, b) => {
          a = a[this.sort.key]
          b = b[this.sort.key]
          return (a === b ? 0 : a > b ? 1 : -1) * (this.sort.isAsc ? 1 : -1)
        });
      }
      return list;
    },
    isFutureLead() {
      return this.$route.name == 'future-lead';
    }
  },
  watch: {
    dialog (val) {
      if (!val) return
      setTimeout(() => (this.dialog = false), 4000)
    },
  },
  methods: {
    changePassword(item) {
      this.$refs['user-password-change'].changePassword(item);
    },

    openDialog(item) {
      this.dialogFutureLead = true;
      this.studentDetail = item;
    },

    sortedClass(key) {
      return this.sort.key === key ? `sorted ${this.sort.isAsc ? 'asc' : 'desc'}` : '';
    },

    sortBy(key) {
      this.sort.isAsc = this.sort.key === key ? !this.sort.isAsc : false;
      this.sort.key = key;
    },

    createUser(){
      this.$refs['create-and-update'].createUser('Student');
    },

    editUser(item){
      this.$refs['create-and-update'].editUser('Student', item);
    },

    studentProfile(id){
      this.$router.push({
        name:'student-profile',
        params:{user_id:id},
      })
    },

    getAllUsers(){
      this.loading = true;
      user
          .paginate(this.search,this.page)
          .then(response => {
            this.users = response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
            this.loading = false;
          })
          .catch((err) => {
            this.$snotify.error("Oops something went wrong");
          });
    },

    searchUser(){
      this.getAllUsers();
    },

    updateFutureLead(item) {
      this.$confirm({
        message: `Are you sure you want update this ? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            item.is_future_lead = !item.is_future_lead;
            user
                .update(item.id, item)
                .then((response) => {
                  this.getAllUsers()
                  this.$snotify.success("Update Successfully ");
                })
                .catch((err) => {
                  this.$snotify.error("Oops something went wrong");
                });
          }
        },
      });
    },

    makeAgent(id) {
      this.$confirm({
        message: `Are you sure to make Agent? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            this.dialog = true;
            user
                .makeAgent(id)
                .then((response) => {
                  this.getAllUsers()
                  this.$snotify.success("Done Successfully ");
                })
                .catch((err) => {
                  this.$snotify.error("Oops something went wrong");
                });
          }
        },
      });
    },

    deleteUser(id) {
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            user
                .delete(id)
                .then((response) => {
                  this.getAllUsers()
                  this.$snotify.success("Candidate deleted");
                })
                .catch((err) => {
                  this.$snotify.error("Oops something went wrong");
                });
          }
        },
      });
    },
  }
}
</script>
<style lang="sass">
table
  th.sorted
    &.asc::after
      display: inline-block
      content: '▼'
      color: red

    &.desc::after
      display: inline-block
      content: '▲'
      color: red

</style>